









































































import { DataTableHeader } from "vuetify";
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import SnackbarModule from "@/store/snackbarModule";
import nameof from "@/utility/nameof";
import Api from "@/api";
import { VesselDocumentModel } from "@/api/generated";
import UserModule from "@/store/userModule";

const userModule = getModule(UserModule);
const snackbarModule = getModule(SnackbarModule);

@Component
export default class VesselDocumentTable extends Vue {
  @Prop(String) private vesselId!: string;
  @Prop({ default: "Vessel Documents" }) title?: string;

  private loading = false;
  private showArchived = false;
  private documents: Array<VesselDocumentModel> = [];
  private headers: Array<DataTableHeader<VesselDocumentModel>> = [
    { text: "", value: "actions" },
    { text: "Filename", value: nameof<VesselDocumentModel>("filename") },
    {
      text: "Reference",
      value: nameof<VesselDocumentModel>("reference"),
    },
    {
      text: "Document Number",
      value: nameof<VesselDocumentModel>("documentNumber"),
    },
    // the View Column will show up here if the user is not agency (see 'created' life cycle event)
    {
      text: "Start Date",
      value: nameof<VesselDocumentModel>("startDate"),
    },
    {
      text: "End Date",
      value: nameof<VesselDocumentModel>("endDate"),
    },
    {
      text: "Uploaded",
      value: nameof<VesselDocumentModel>("createdUtc"),
    },
  ];

  private get agencyAdminRole() {
    return userModule.role == "AGM";
  }

  private async created() {
    await this.getDocuments();
    if (this.agencyAdminRole == false) {
      this.headers.splice(4, 0, {
        text: "View",
        value: nameof<VesselDocumentModel>("adminViewOnly"),
      });
    }
  }

  @Watch("vesselId", { immediate: true })
  private async getDocuments() {
    try {
      this.loading = true;

      if (this.agencyAdminRole) {
        const response = await Api.VesselDocumentService.apiVesseldocumentVesselAgencyVesselIdGet(
          this.vesselId
        );
        this.documents = response.data;
      } else {
        const response = await Api.VesselDocumentService.apiVesseldocumentVesselVesselIdGet(
          this.vesselId
        );
        this.documents = response.data;
      }
    } catch {
      snackbarModule.setSnackbarMessage("Failed to fetch documents");
    } finally {
      this.loading = false;
    }
  }

  get filteredDocuments(): Array<VesselDocumentModel> {
    if (this.documents.length > 0) {
      return this.documents.filter((x) => x.archived == this.showArchived);
    }
    return [];
  }
}
