var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vessel-document-update-dialog"},[_c('v-dialog',{attrs:{"max-width":"800px"},model:{value:(_vm.syncedDialog),callback:function ($$v) {_vm.syncedDialog=$$v},expression:"syncedDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"font-weight-bold"},[_vm._v(" Update Document ")]),_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.onDocumentUpdate($event)}}},[_c('v-card-text',[_c('base-input',{attrs:{"rules":[_vm.requiredRule],"placeholder":"Filename","topLabel":"Filename"},model:{value:(_vm.model.filename),callback:function ($$v) {_vm.$set(_vm.model, "filename", $$v)},expression:"model.filename"}}),_c('base-input',{attrs:{"placeholder":"Reference","topLabel":"Reference"},model:{value:(_vm.model.reference),callback:function ($$v) {_vm.$set(_vm.model, "reference", $$v)},expression:"model.reference"}}),_c('base-input',{attrs:{"topLabel":"Document Number","placeholder":"Document Number"},model:{value:(_vm.model.documentNumber),callback:function ($$v) {_vm.$set(_vm.model, "documentNumber", $$v)},expression:"model.documentNumber"}}),_c('base-date-picker',{attrs:{"topLabel":"Start Date","placeholder":"Start Date","rules":[
              _vm.dateBefore(
                _vm.model.startDate,
                _vm.model.endDate,
                'Start Date',
                'End Date'
              ) ]},model:{value:(_vm.model.startDate),callback:function ($$v) {_vm.$set(_vm.model, "startDate", $$v)},expression:"model.startDate"}}),_c('base-date-picker',{attrs:{"topLabel":"End Date","placeholder":"End Date","rules":[
              _vm.dateBefore(
                _vm.model.startDate,
                _vm.model.endDate,
                'Start Date',
                'End Date'
              ) ]},model:{value:(_vm.model.endDate),callback:function ($$v) {_vm.$set(_vm.model, "endDate", $$v)},expression:"model.endDate"}}),_c('v-checkbox',{attrs:{"label":"Admin View Only"},model:{value:(_vm.model.adminViewOnly),callback:function ($$v) {_vm.$set(_vm.model, "adminViewOnly", $$v)},expression:"model.adminViewOnly"}})],1),_c('v-card-actions',{staticClass:"dialog-actions"},[_c('v-spacer'),_c('base-secondary-btn',{on:{"click":function($event){_vm.syncedDialog = false}}},[_vm._v("Cancel")]),_c('base-primary-btn',{attrs:{"type":"submit","loading":_vm.loading}},[_vm._v("Save")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }